<template>
  <div class="content-container guess-word">

    <div class="category-box" :class="{'show' : isShow}" ref="categoryBox"
         :style="{left: left + 'px',top: top + 'px'}">
      <subject-category-tree v-model="currentCategoryId" :show-checkbox="true" @checked="checkHandler"
                             @expanded="expandHandler" @collapsed="collapseHandler"/>
    </div>

    <div class="subject-box">

      <div class="image-box">
        <ImagePreview :src="answer.image" alt="" :max-height="220"/>
      </div>

      <div class="answer-box">

        <el-checkbox-group v-model="fillList" size="medium">
          <el-checkbox-button v-for="(option,index) in answerList" :label="option.id" :key="option.id"
                              style="min-height: 36px"
                              @change="answerSelected(option)">{{option.name}}
          </el-checkbox-button>
        </el-checkbox-group>


        <div class="result" v-if="isShowResult">
          <img src="@/assets/img/exam/Right.png" v-if="selectedName==answer.wordName"/>
          <img src="@/assets/img/exam/Wrong.png" v-if="selectedName!=answer.wordName"/>
        </div>

        <word-viewer v-model="answer" v-if="isShowingAnalysis"/>

      </div>

      <div class="subject-buttons">
        <el-button plain @click="showAnalysis" :disabled="!canShowAnalysis">查看解析</el-button>
        <el-button plain @click="next" :loading="loadingNext">下一题</el-button>
      </div>

      <div class="subject-options">

        <el-checkbox-group v-model="selectedList" size="medium" v-for="(optionLine,index) in options" :key="index">
          <el-checkbox-button v-for="option in optionLine" :label="option.id" :key="option.id" :checked="option.checked"
                              @change="optionSelected(option)">{{option.name}}
          </el-checkbox-button>
        </el-checkbox-group>

        <div style="clear: both"></div>

      </div>

    </div>

    <float-button v-if="isPhone">
      <img src="@/assets/img/core-img/category.png" style="margin: 15%;width: 70%" @click="showCategory"/>
    </float-button>

  </div>
</template>

<script>

  const {body} = document;
  const WIDTH = 1060; // refer to Bootstrap's responsive design

  import {imageGuessWord} from "../../api/exam/practice";
  import WordViewer from '@/components/WordViewer'
  import SubjectCategoryTree from '@/components/Subjects/SubjectCategoryTree'
  import FloatButton from '@/components/FloatButton'
  import {wexinShare} from '@/utils/weixinSDK'

  export default {
    name: "imageGuessWord",
    components: {WordViewer, SubjectCategoryTree, FloatButton},
    data() {
      return {
        loadingNext: false,
        currentCategoryId: null,
        query: {
          categoryIds: []
        },
        answerImg: null,
        answer: {
          id: null,
          image: ''
        },
        options: [],
        selectedName: '',
        fillList: [],
        answerList: [],
        selectedAnswer: null,
        selectedList: [],
        isShowResult: false,
        canShowAnalysis: false,
        isShowingAnalysis: false,
        shareData: {
          title: '',
          description: '',
          url: '',
          imgUrl: '',
        },

        isPhone: false,
        top: 0,
        left: 0,
        isSelected: false,
        isShow: false,
      }
    },
    created() {
      this.resizeHandler();
      this.getSubject();
      this.wxShareInit();
    },
    mounted() {
      this.resizeHandler()
    },
    beforeMount() {
      window.addEventListener('resize', this.resizeHandler)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeHandler)
    },
    methods: {
      getSubject() {
        this.loadingNext = true;
        imageGuessWord(this.query).then((resp) => {
          this.answer = resp.data.answer;
          this.answerList = new Array();
          for (var i = 0; i < this.answer.wordName.length; i++) {
            var item = new Object();
            item.id = i;
            item.name = " ";
            item.checked = false;
            this.answerList.push(item);
          }

          this.selectedAnswer = this.answerList[0];
          this.fillList.push(this.answerList[0].id);

          var optionGroup = [];

          var lineCount = 10;

          if (this.isPhone) lineCount = 6;

          var line = parseInt(resp.data.optionList.length / lineCount);
          var mod = resp.data.optionList.length % lineCount;
          if (mod > 0) line += 1;
          console.log(line)


          var index = 0;
          for (var i = 0; i < line; i++) {
            var optionLine = [];
            for (var j = 0; j < lineCount; j++) {
              var item = new Object();
              item.id = index;
              item.name = resp.data.optionList[index];
              item.checked = false;

              optionLine.push(item);
              index++;
            }
            optionGroup.push(optionLine);
          }

          this.options = optionGroup;

          this.loadingNext = false;
          window.scroll(0, 0);
        }).catch((error) => {
          messageFail(this, '获取题目失败')
          this.loadingNext = false;
        });
      },
      next() {
        this.isShowingAnalysis = false;
        this.canShowAnalysis = false;
        this.isShowResult = false;
        this.fillList = new Array();
        this.answerList = new Array();
        this.getSubject();
      },
      showAnalysis() {
        this.isShowingAnalysis = true;
      },
      toggleOption(option) {
        this.isShowResult = true;
        this.canShowAnalysis = true;
      },
      checkHandler(nodes) {
        this.query.categoryIds = nodes.checkedKeys;
        this.getSubject();
        this.showCategory();
      },
      answerSelected(selected) {
        var id = this.fillList[0];
        var his = this.answerList[id];

        this.fillList.splice(0, this.fillList.length);
        this.fillList.push(selected.id);
        this.selectedAnswer = selected;
      },
      optionSelected(selected) {

        var index = this.answerList.indexOf(this.selectedAnswer);

        this.selectedAnswer.name = selected.name;

        if (index < (this.answerList.length - 1)) {
          this.fillList.splice(0, this.fillList.length);
          this.fillList.push(this.answerList[index + 1].id);
          this.selectedAnswer = this.answerList[index + 1];
        } else {
          for (var i = 0; i < this.answerList.length; i++) {
            var item = this.answerList[i];
            if (item.name == ' ') {
              this.fillList.splice(0, this.fillList.length);
              this.fillList.push(item.id);
              this.selectedAnswer = item;
            }
          }
        }

        this.selectedList.splice(0, this.selectedList.length);
        this.checkAnswer();
      },
      checkAnswer() {
        var wordName = '';
        var isFull = true;
        for (var i = 0; i < this.answerList.length; i++) {
          var item = this.answerList[i];
          if (item.name == ' ') {
            isFull = false;
            break;
          }

          wordName += item.name;
        }

        if (!isFull) return;

        this.isShowResult = true;
        this.selectedName = wordName;
        this.canShowAnalysis = true;

      },


      isMobile() {
        const rect = body.getBoundingClientRect();
        var isMobile = rect.width - 1 < WIDTH;
        this.isPhone = isMobile;
        return isMobile;
      },
      resizeHandler() {
        if (document.hidden) {
          return;
        }

        const isMobile = this.isMobile()


      },
      setPosition() {
        var clientW = document.documentElement.clientWidth;
        var clientH = document.documentElement.clientHeight;
        var boxW = this.$refs.categoryBox.clientWidth;
        var boxH = this.$refs.categoryBox.clientHeight;

        var left = 10;
        var top = clientH - boxH - 10;
        this.left = left;
        this.top = top;

      },
      showCategory() {

        // 取反
        this.isSelected = !this.isSelected;

        // 修改列表的显示与隐藏
        if (this.isSelected === true) {
          this.isShow = this.isShow === false;

        } else {
          this.isShow = this.isShow === false;
        }

        if (this.isShow) {
          this.setPosition();
        }

      },
      expandHandler(data) {
        console.log('expandHandler')
        var that = this;
        var t1 = setTimeout(function () {
          that.setPosition();
          clearTimeout(t1);
        }, 300)

      },
      collapseHandler(data) {
        console.log('collapseHandler')
        var that = this;
        var t1 = setTimeout(function () {
          that.setPosition();
          clearTimeout(t1);
        }, 300)
      },
      wxShareInit() {
        var shareUrl = window.location.href;
        this.shareData.title = '看图猜手语';
        //this.shareData.imgUrl = this.exam.logoUrl;
        this.shareData.url = shareUrl;
        this.shareData.description = '看图猜手语词汇，您能猜对多少个？';

        wexinShare(this.shareData);

      },


    }
  }
</script>

<style>
  .guess-word .el-checkbox-button--medium .el-checkbox-button__inner {
    font-size: 1rem !important;
    font-weight: 600 !important;
  }
</style>

<style scoped>
  @import "../../assets/css/imageGuessWord.scss";


  @media (max-width: 1160px) {

    .subject-options {
      margin-left: 0rem;
      text-align: center;
    }

    .category-box {
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid #efefef;
      background: transparent;
      visibility: hidden;
      opacity: 0;
      z-index: 999;
      overflow-y: scroll;
    }

    .category-box.show {
      width: 80%;
      max-width: 360px;
      visibility: visible;
      opacity: 1;
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      background: white;
      /*height: 80vh;*/
      max-height: 80vh;
    }


    .subject-box {
      width: 100% !important;
      margin-bottom: 3rem !important;
      padding-bottom: 10rem !important;
    }


    .subject-buttons, .subject-options, .answer-box {
      margin-top: 1rem !important;
    }

    .result {
      position: absolute;
      right: 2rem;
      margin-right: 1rem !important;
      margin-top: -2.2rem !important;
    }

  }

</style>
